import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import _ from 'lodash'
import { dataRequest, defaultFetchData, queryNotComplete, getIndicatorData } from '../../utils/helper'
import { valueFormatter } from '../../utils/format'

import { useIndicators } from '../../hooks/use-indicators'
import GridComponent from './grid-component'

import './indicator-grid.scss'

const IndicatorGrid = () => {
    const [comparisonData, setComparisonData] = useState([])
    const indicators = useIndicators()
    const filteredIndicators = indicators.filter((d) => d.Indicator_code.match(/^[a-z0-9]+$/i))

    const comparisonRequest = dataRequest('comparison')
    const comparisonQuery = useQuery('comparisonQuery', () => defaultFetchData(comparisonRequest))

    useEffect(() => {
        if (!queryNotComplete(comparisonQuery)) {
            setComparisonData(comparisonQuery?.data)
        }
    }, [!queryNotComplete(comparisonQuery)])

    return (
        <>
            <h1 className="executive-summary-indicator-grid-title">Data from the Indicators for an Inclusive Regional Economy</h1>
            <div className="executive-summary-indicator-grid">
                {filteredIndicators.map((indicator) => {
                    const indicatorColHeader = getIndicatorData(indicator.Indicator_code)
                    const data = generateChartData(comparisonData, indicator, indicatorColHeader)
                    const sortedData = _.orderBy(data, ['parentCategory'], ['asc'])
                    return (
                        <div className="col" key={indicator.Indicator_code}>
                            <GridComponent metadata={indicator} data={sortedData} />
                        </div>
                    )
                })}
            </div>
        </>
    )
}

export default IndicatorGrid

//Generate data object for bar chart
const generateChartData = (queryData, metadata, indicatorColHeader) => {
    let unit = metadata.Unit_of_measurement
    const errorBar = metadata.Margin_of_error
    const decimals = metadata.Decimals
    let data = queryData
        .map((row) => {
            const value = valueFormatter(row[indicatorColHeader], unit, decimals)
            const baseObject = { childCategory: row.Cultural_Community, parentCategory: row.Race, value }
            const extendedObject =
                errorBar === 'TRUE'
                    ? { upperValue: valueFormatter(row[`${indicatorColHeader}_upper`], unit, decimals), lowerValue: valueFormatter(row[`${indicatorColHeader}_lower`], unit, decimals) }
                    : null
            return { ...baseObject, ...extendedObject }
        })
        .filter((d) => d.value && d.parentCategory !== 'All') //Remove empty values and All category
    return data
}
