import * as React from 'react'

import InclusiveGrowthSvg from '../../images/InclusiveGrowth.svg'
import BusinessDevelopmentSvg from '../../images/BusinessDevelopment.svg'
import HumanCapitalSvg from '../../images/HumanCapital.svg'
import SenseBelongingSvg from '../../images/SenseBelonging.svg'
import AccessOpportunitySvg from '../../images/AccessOpportunity.svg'

import { COLOR_MAP_TOPICS } from '../../utils/theme'
import { Topics } from '../../utils/enum'

import './topics-list.scss'

const TopicsList = ({ list }) => {
    return (
        <>
            {list.map((topic) => {
                const path = topicIconPath(topic.Topic_area)
                const content = `<strong>${topic.Topic_area}</strong> - ${topic.Topic_area_description}`
                return (
                    <div className="topics-list-item" key={topic.Topic_area}>
                        <div className="icon">
                            <img src={path} />
                        </div>
                        <div
                            style={{
                                color: COLOR_MAP_TOPICS[topic.Topic_area],
                            }}
                            className="item-content"
                            dangerouslySetInnerHTML={{ __html: content }}
                        />
                    </div>
                )
            })}
        </>
    )
}

export default TopicsList

const topicIconPath = (topic) => {
    switch (topic) {
        case Topics.BusinessDevelopment:
            return BusinessDevelopmentSvg
        case Topics.HumanCapital:
            return HumanCapitalSvg
        case Topics.InclusiveGrowth:
            return InclusiveGrowthSvg
        case Topics.SenseBelonging:
            return SenseBelongingSvg
        case Topics.AccessOpportunity:
            return AccessOpportunitySvg
        default:
            console.log('Icon not found.')
    }
}
