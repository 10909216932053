import React, { useRef, useEffect, useState } from 'react'
import classNames from 'classnames'
import { scaleLinear } from 'd3-scale'

import { computeDataDomain } from '../../utils/axis'
import { ColorLines, ComparisonColors } from '../../utils/theme'
import { OPACITY } from '../../utils/const'
import { isChildren } from '../../utils/helper'
import { unitFormatter } from '../../utils//format'

import Line from '../common/svg/line'

import './small-bar-chart.scss'

const MARGIN = {
    top: 5,
    left: 155,
    right: 15,
    bottom: 0,
}

const BAR_HEIGHT = 12
const DIAMOND_WIDTH = 0.75

const SmallBarChart = ({ data, unit, decimals }) => {
    const barRef = useRef(null)
    const chartRef = useRef(null)

    const [width, setWidth] = useState(0)
    const [height, setHeight] = useState(0)

    useEffect(() => {
        if (chartRef.current && barRef.current && data.length > 0) {
            setWidth(barRef.current.clientWidth)
            setHeight(chartRef.current.clientHeight)
        }
    }, [data, barRef, chartRef])

    const chartWidth = width - MARGIN.right
    const xAxisScaleDomain = computeDataDomain(data, unit)
    const xAxisScale = scaleLinear().domain(xAxisScaleDomain).range([0, chartWidth]).nice()
    let tickValues = xAxisScale.ticks(5)

    let diamondPadding = 1

    return (
        <div className="small-bar-chart-container">
            <div className="small-bar-chart-axis-lines-container">
                <svg className="small-bar-chart-axis-lines" transform={`translate(${5},${MARGIN.top})`}>
                    {tickValues.map((value) => {
                        const lineData = [
                            [xAxisScale(value), 0],
                            [xAxisScale(value), height],
                        ]
                        return <Line key={`line-${value}`} data={lineData} styles={{ strokeWidth: 1, opacity: 1, stroke: ColorLines.LightGray }} />
                    })}
                </svg>
            </div>
            <div className="small-bar-chart" ref={chartRef}>
                {data.map((row, idx) => {
                    const percentReferencePoint = (xAxisScale(0) * 100) / xAxisScale.range()[1]
                    const barWidth = transformToPercent(row.value, xAxisScale)
                    const barPosition = row.value >= 0 ? percentReferencePoint : percentReferencePoint - barWidth
                    const color = row.value >= 0 ? ComparisonColors.Positive : ComparisonColors.Negative
                    let barStyles = {
                        width: `${barWidth}%`,
                        backgroundColor: color,
                        opacity: isChildren(row) ? OPACITY.childrenOpacity : OPACITY.parentOpacity,
                        left: `${barPosition}%`,
                    }
                    let barClasses = classNames('small-bar-wrapper', { 'is-parent': !isChildren(row) })

                    const diamondPosition = row.value < 0 ? barPosition - barWidth - DIAMOND_WIDTH * 0.5 : barPosition - DIAMOND_WIDTH * 0.5
                    let diamondStyles = {
                        width: `${DIAMOND_WIDTH}%`,
                        height: BAR_HEIGHT,
                        left: `${diamondPosition}%`,
                        backgroundColor: color,
                    }
                    let valueStyles = { color: color, left: `${barPosition}%` }
                    const formattedValue = unitFormatter(row.value, unit, decimals)
                    const label = `${row.childCategory} ${formattedValue}`

                    return (
                        <div className="small-bars" key={`bar-${idx}`}>
                            <div className={barClasses} ref={barRef}>
                                <div className="small-bar" style={barStyles} />
                                <div className="diamond-shape" style={diamondStyles} />
                                <div className="value-label" style={valueStyles}>
                                    {label}
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default SmallBarChart

const transformToPercent = (value, scale) => {
    return (Math.abs(scale(value) - scale(0)) * 100) / scale.range()[1]
}
