import { useStaticQuery, graphql } from 'gatsby'

export const useTopics = () => {
    const data = useStaticQuery(
        graphql`
            query Topics {
                allMetadataCsv {
                    nodes {
                        Topic_area
                        Topic_area_description
                    }
                }
            }
        `,
    )

    const topics = [...new Set(data?.allMetadataCsv?.nodes)].filter((d) => d.Topic_area_description !== '')
    return topics
}
