import * as React from 'react'

import './content-wrapper.scss'

const ContentWrapper = ({ children, data }) => {
    return (
        <div className="content-wrapper-container">
            <h2 className="title" dangerouslySetInnerHTML={{ __html: data.title }} />
            <div className="content" dangerouslySetInnerHTML={{ __html: data.content.replace(/href/g, "target='_blank' href") }} />
            {children}
        </div>
    )
}

export default ContentWrapper
