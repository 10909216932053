import * as React from 'react'

import './content.scss'

const Content = ({ children, header, content }) => {
    return (
        <div className="content-container">
            <div className="header">{header}</div>
            <div className="content" dangerouslySetInnerHTML={{ __html: content }} />
            {children}
        </div>
    )
}

export default Content
