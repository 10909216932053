import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { QueryClient, QueryClientProvider } from 'react-query'
import { StaticImage } from 'gatsby-plugin-image'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import { useTopics } from '../hooks/use-topics'

import SEO from '../components/seo'
import IndicatorLayout from '../components/layouts/indicator-layout'
import IndicatorHeader from '../components/indicators/indicator-header'
import Content from '../components/executiveSummary/content'
import TopicsList from '../components/executiveSummary/topics-list'
import IndicatorGrid from '../components/executiveSummary/indicator-grid'
import SimpleButton from '../components/controls/simple-button'
import ContentWrapper from '../components/common/content-wrapper'

import { Topics, ButtonTypes } from '../utils/enum'
import { ButtonsColors } from '../utils/theme'
import { ColorGraphViz } from '../utils/theme'

import './executiveSummary.scss'

const queryClient = new QueryClient()
const ExecutiveSummaryPage = () => {
    const topics = useTopics()
    const data = useStaticQuery(graphql`
        query executiveSummaryQuery {
            allExecutiveSummaryCsv {
                nodes {
                    Title
                    Intro_section_header
                    Intro_section_content
                    Strategy_section_description
                    Strategy_section_title
                    Strategy_subsection_content_1
                    Strategy_subsection_content_2
                    Strategy_subsection_content_3
                    Strategy_subsection_content_4
                    Strategy_subsection_title_1
                    Strategy_subsection_title_2
                    Strategy_subsection_title_3
                    Strategy_subsection_title_4
                    Read_more
                }
            }
        }
    `)

    const [copied, setCopied] = useState(false)
    const [url, setUrl] = useState('')
    const [showReadMore, setShowReadMore] = useState(false)

    useEffect(() => {
        setUrl(window.location.href)
    }, [])

    const metadata = data.allExecutiveSummaryCsv.nodes[0]
    const linkButtonStyle = { color: ButtonsColors.Selected, border: `1px solid ${ButtonsColors.Selected}`, textTransform: 'none' }
    const strategyContent = [
        { title: metadata.Strategy_subsection_title_1, content: metadata.Strategy_subsection_content_1 },
        { title: metadata.Strategy_subsection_title_2, content: metadata.Strategy_subsection_content_2 },
        { title: metadata.Strategy_subsection_title_3, content: metadata.Strategy_subsection_content_3 },
        { title: metadata.Strategy_subsection_title_4, content: metadata.Strategy_subsection_content_4 },
    ]

    const readMoreButtonStyle = { color: ColorGraphViz.DarkGray, textTransform: 'none', fontSize: '16px', textAlign: 'left', fontWeight: 'bold' }

    const handleCopyToClipboard = () => {
        setCopied(true)
    }

    const handleReadMore = () => {
        setShowReadMore(!showReadMore)
    }

    return (
        <QueryClientProvider client={queryClient}>
            <IndicatorLayout>
                <SEO />
                <IndicatorHeader category={Topics.InclusiveGrowth} name={'Executive Summary'} />
                <StaticImage className="img-container" imgClassName="img-bg" src="../images/ExecutiveSummary.jpg" alt="Executive summary" objectPosition="0% 20%" />
                <div className="additional-content">
                    <Content header={metadata.Intro_section_header} content={metadata.Intro_section_content}>
                        <SimpleButton
                            variant={ButtonTypes.Text}
                            label={showReadMore ? 'Read less' : 'Read more'}
                            iconName={showReadMore ? 'Remove' : 'Add'}
                            iconOrder={'end'}
                            customization={readMoreButtonStyle}
                            onClick={handleReadMore}
                        />
                        {showReadMore && <div className="content" dangerouslySetInnerHTML={{ __html: metadata.Read_more }} />}
                    </Content>
                    <TopicsList list={topics} />
                    <IndicatorGrid />
                    <h1 className="strategy-title">{metadata.Strategy_section_title}</h1>
                    <div className="strategy-content">
                        {strategyContent.map((item, idx) => {
                            return <ContentWrapper data={item} key={idx} />
                        })}
                    </div>
                    <CopyToClipboard text={url} onCopy={() => handleCopyToClipboard()}>
                        <div className="share-button">
                            <SimpleButton variant={ButtonTypes.Outlined} label={'Copy link to this page to your clipboard'} customization={linkButtonStyle} iconName="Share" />
                        </div>
                    </CopyToClipboard>
                </div>
            </IndicatorLayout>
        </QueryClientProvider>
    )
}

export default ExecutiveSummaryPage
