import React from 'react'
import { navigate } from 'gatsby'

import { COLOR_MAP_TOPICS } from '../../utils/theme'
import SmallBarChart from './small-bar-chart'
import SmallDivergingChart from './small-diverging-chart'

import NavigationButton from '../controls/navigation-button'

import './grid-component.scss'

const GridComponent = ({ metadata, data }) => {
    const { Topic_area, Geography, Year___Point_in_time, Indicator_short_name, Indicator_long_name, Indicator_code, Unit_of_measurement, Decimals } = metadata

    const handleClick = () => {
        navigate(`/indicators/${Indicator_code}`)
    }

    const subtitle = `${Geography}, ${Year___Point_in_time}`
    return (
        <div className="grid-component">
            <div className="title" style={{ color: COLOR_MAP_TOPICS[Topic_area] }}>
                {Topic_area}
            </div>
            <div onClick={() => handleClick()}>
                <NavigationButton label={Indicator_short_name} topic={Topic_area} />
            </div>
            <div className="subtitle" style={{ color: COLOR_MAP_TOPICS[Topic_area] }}>
                {subtitle}
            </div>
            {Indicator_code !== 'sb3' && (
                <div>
                    <SmallBarChart data={data} unit={Unit_of_measurement} decimals={Decimals} />
                </div>
            )}
            {Indicator_code === 'sb3' && (
                <div>
                    <SmallDivergingChart data={data} unit={Unit_of_measurement} decimals={'one'} />
                </div>
            )}

            <div className="long-name">{Indicator_long_name}</div>
        </div>
    )
}

export default GridComponent
